
import { defineComponent } from "vue";
import UpsertAudio from "@/views/static-room/child/audio/UpsertAudio.vue";
import ListAudio from "@/views/static-room/child/audio/ListAudio.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  AudioActions,
  AudioGetters,
  AudioMutations,
} from "@/store/vm/static-room/media/audio/enums";

export default defineComponent({
  name: "audio-component",
  components: {
    UpsertAudio,
    ListAudio,
  },
  computed: {},
  emits: ["change"],
  setup() {
    const store = useStore();
    const onDel = (audioId) => {
      const detail = store.getters[AudioGetters.GET_BY_ID](audioId);

      Swal.fire({
        title: "Bạn muốn xóa audio?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await actionDelete(audioId);
        }
      });
    };

    const actionDelete = async (audioId) => {
      return store.commit(AudioActions.DELETE, audioId);
    };

    const onUpdate = async (audioId) => {
      return store.commit(AudioMutations.SET_AUDIO_ID_TARGET_UPSERT, audioId);
    };

    return {
      onDel,
      onUpdate,
    };
  },
});
