
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "upsert-require-roles",
  props: {
    value: {
      type: String,
      default: "all",
    },
  },
  setup(props) {
    const isRequireRole = ref<boolean>(false);
    const checkedTeacher = ref<boolean>(true);
    const checkedStudent = ref<boolean>(true);
    const checkedGuest = ref<boolean>(true);
    const checkedEmployee = ref<boolean>(true);
    const checkedMarketer = ref<boolean>(true);
    const checkedSaler = ref<boolean>(true);

    const valueProp = computed(() => {
      return props.value;
    });

    watch(valueProp, () => {
      apply();
    });

    const apply = () => {
      let roles = ["all"];
      if (props.value && props.value.split) {
        roles = props.value?.split(",");
      }

      if (roles.length === 1 && roles[0] === "all") {
        isRequireRole.value = false;
      } else {
        isRequireRole.value = true;
        checkedTeacher.value = roles.includes("teacher");
        checkedStudent.value = roles.includes("student");
        checkedGuest.value = roles.includes("guest");
        checkedEmployee.value = roles.includes("employee");
        checkedMarketer.value = roles.includes("marketer");
        checkedSaler.value = roles.includes("saler");
      }
    };

    const get = (): string[] => {
      if (!isRequireRole.value) {
        return ["all"];
      } else {
        const roles: string[] = [];
        if (checkedTeacher.value) roles.push("teacher");
        if (checkedStudent.value) roles.push("student");
        if (checkedGuest.value) roles.push("guest");
        if (checkedEmployee.value) roles.push("employee");
        if (checkedMarketer.value) roles.push("marketer");
        if (checkedSaler.value) roles.push("saler");
        return roles;
      }
    };

    return {
      isRequireRole,
      checkedTeacher,
      checkedStudent,
      checkedGuest,
      checkedEmployee,
      checkedMarketer,
      checkedSaler,
      get,
      apply,
    };
  },
});
