<template>
  <div>
    <div
      class="modal bg-white fade"
      tabindex="-1"
      aria-hidden="true"
      :id="modalUploadAudioId"
      ref="modalUploadAudio"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-fullscreen">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="__modalConfig.id___header">
            <!--begin::Modal title-->
            <h2 class="fw-bolder">Upload audio</h2>
            <div
              data-bs-dismiss="modal"
              class="btn btn-icon btn-sm btn-active-icon-primary"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body py-10 px-lg-10">
            <div class="row g-9 mb-7">
              <div class="col-md-3 fv-row">
                <input
                  :id="inputSelectFileId"
                  type="file"
                  name="image"
                  accept="audio/*"
                  @change="setAudioAndUpload"
                />
                <br />
              </div>
            </div>
          </div>

          <!-- Cropper container -->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <button
              :data-kt-indicator="uploading ? 'on' : null"
              class="btn btn-lg btn-primary"
              @click="uploadAudio()"
            >
              <span v-if="!uploading" class="indicator-label">
                {{ $t("common.submit") }}
                <span class="svg-icon svg-icon-3 ms-2 me-0">
                  <inline-svg src="icons/duotune/arrows/arr064.svg" />
                </span>
              </span>
              <span v-if="uploading" class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </div>
      </div>
    </div>

    <div v-show="false">
      <button
        class="btn"
        :id="btnShowModalId"
        data-bs-toggle="modal"
        :data-bs-target="`#${modalUploadAudioId}`"
      >
        Show modal
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { generateRandomString } from "@/core/helpers/documentation";

export default {
  name: "upload-audio",
  components: {},
  props: {
    modalUploadAudioId: String,
  },
  data: function () {
    return {
      imageSrc: "",
    };
  },
  methods: {
    selectFile: function () {
      document.getElementById(this.inputSelectFileId).click();
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const modalUploadAudio = (ref < null) | (HTMLButtonElement > null);
    const inputSelectFileId = "input-select-file-audio-id";
    let uploading = ref(false);
    const getTokenUpload = async () => {
      return await store
        .dispatch("getTokenUpload", {
          class: "static_room",
          type: "audio",
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const btnShowModalId = "button-show-modal-id-" + generateRandomString(6);

    const configUpload = {
      url: "https://upload-vm.daybreak.icu/upload/file_audio",
    };

    const uploadAudio = async () => {
      uploading = true;
      const config = await getTokenUpload();
      var formData = new FormData();
      formData.append("name", config.name);
      formData.append("exp", config.exp);
      formData.append("class", config.class);
      formData.append("hmac", config.hmac);
      formData.append("extra", config.extra);
      formData.append(
        "file_rts",
        document.getElementById(inputSelectFileId).files[0]
      );

      axios
        .post(configUpload.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            hideModal(modalUploadAudio.value);
            emit("uploadSuccess", response.data.url);
            // document.getElementById(btnShowModalId).click();

            Swal.fire({
              position: "top-end",
              text: "Đã upload thành công!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            uploading = false;
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi!",
              icon: "fail",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });

            emit("uploadFail", response.data);
            uploading = false;
          }

          reset();
        });
    };

    const setAudioAndUpload = async (e) => {
      const file = e.target.files[0];
      if (!file.type.includes("audio/")) {
        alert("Vui lòng chọn đúng định dạng audio: *.mp3, *.wav");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        Swal.fire({
          title: "Đang upload file, vui lòng không chuyển trang!",
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        await uploadAudio();
      } else {
        alert(
          "Error, Trình duyệt không support FileReader API. Vui lòng sử dụng trình duyệt Chrome, Cốc Cốc hoặc FireFox"
        );
      }
    };

    const reset = () => {
      uploading = false;
      document.getElementById(inputSelectFileId).value = "";
    };

    return {
      uploading,
      btnShowModalId,
      configUpload,
      inputSelectFileId,
      getTokenUpload,
      uploadAudio,
      modalUploadAudio,
      setAudioAndUpload,
      reset,
    };
  },
};
</script>
