
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  AudioActions,
  AudioGetters,
  AudioMutations,
} from "@/store/vm/static-room/media/audio/enums";
import UploadAudio from "@/components/upload/upload-audio.vue";
import CropperComponent from "@/../src/components/upload/cropper.vue";

export default defineComponent({
  name: "upsert-audio",
  emits: ["reset"],
  components: {
    UploadAudio,
    cropper: CropperComponent,
  },
  methods: {
    activateUpload() {
      (this.$refs["uploadaudio"] as any).selectFile();
    },
    activateCropper: function () {
      (this.$refs["imagesCropperUpsertAudio"] as any).selectFile();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup() {
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const audioSrcLocal = ref<string>("");

    const defaultData = {
      audioId: "",
      src: "",
      name: "",
      order: 1,
      defaultTime: 10,
      min: 5,
      max: 15,
      desp: "",
      image: "",
    };

    const formData = ref({ ...defaultData });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu nhập tên",
          trigger: "change",
        },
      ],
    });

    const audioIdTarget = computed(() => {
      return store.state.AudioModule.audioIdTargetUpsert;
    });

    watch(audioIdTarget, (currentValue) => {
      if (currentValue !== "") {
        // update
        const detail = store.getters[AudioGetters.GET_BY_ID](currentValue);
        formData.value = detail;
        audioSrcLocal.value = detail.src;
      } else {
        // Only run when current value changed
        reset();
      }
    });

    const isCreate = computed(() => {
      return audioIdTarget.value === "";
    });

    // Vấn đề có thể ở đây
    const reset = () => {
      console.log("on reset, default");
      const defaultDataPrimitive = {
        audioId: "",
        src: "",
        name: "",
        order: 1,
        defaultTime: 10,
        min: 5,
        max: 15,
        desp: "",
        image: "",
      };

      audioSrcLocal.value = "";
      formData.value = defaultDataPrimitive;
    };

    const actionCreate = (par) => {
      store.commit(AudioActions.CREATE, par);

      reset();
    };

    const actionUpdate = async (par) => {
      store.commit(AudioActions.UPDATE, par);
      store.commit(AudioMutations.SET_AUDIO_ID_TARGET_UPSERT, ""); // make reset run because of value changed
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          if (audioIdTarget.value === "") {
            await actionCreate(formData.value);
          } else {
            await actionUpdate(formData.value);
          }
          loading.value = false;
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onUploadSuccess = (url) => {
      audioSrcLocal.value = url;
      formData.value.src = url;
    };

    const onUploadFail = (message) => {
      console.log(message);
    };

    const onUploadCoverSuccess = (imageUrl) => {
      formData.value.image = imageUrl;
    };

    return {
      audioIdTarget,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      onUploadSuccess,
      onUploadFail,
      onUploadCoverSuccess,
      audioSrcLocal,
      reset,
    };
  },
});
