
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "list-component",
  computed: {},
  emits: ["update", "del"],
  setup(props, { emit }) {
    const store = useStore();
    const list = computed(() => {
      return store.state.VideoModule.list;
    });

    const updateVideo = (videoId) => {
      emit("update", videoId);
    };

    const delVideo = (videoId) => {
      emit("del", videoId);
    };

    return {
      list,
      updateVideo,
      delVideo,
    };
  },
});
