
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "list-component",
  computed: {},
  emits: ["update", "del"],
  setup(props, { emit }) {
    const store = useStore();
    const list = computed(() => {
      return store.state.AudioModule.list;
    });

    const updateAudio = (audioId) => {
      emit("update", audioId);
    };

    const delAudio = (audioId) => {
      emit("del", audioId);
    };

    return {
      list,
      updateAudio,
      delAudio,
    };
  },
});
