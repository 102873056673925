
/* eslint-disable @typescript-eslint/no-explicit-any */

import { computed, defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StaticRoomActions } from "@/store/vm/static-room/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import TinyMCE from "@/components/editor/tinyMCE.vue";
import RequireRole from "@/views/static-room/child/require-roles/Upsert.vue";
import Audio from "@/views/static-room/child/audio/IndexAudio.vue";
import Video from "@/views/static-room/child/video/IndexVideo.vue";
import CropperComponent from "@/../src/components/upload/cropper.vue";
import { AudioMutations } from "@/store/vm/static-room/media/audio/enums";
import { VideoMutations } from "@/store/vm/static-room/media/video/enums";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import router from "@/router";

export default defineComponent({
  name: "upsert-staticRoom",
  components: {
    tiny: TinyMCE,
    Audio: Audio,
    Video: Video,
    cropper: CropperComponent,
    RequireRole: RequireRole,
  },
  methods: {
    activateCropper: function () {
      (this.$refs["coverCropper"] as any).selectFile();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const requireRoleRef = ref();
    const loading = ref<boolean>(false);

    const staticRoomId =
      route.params["staticRoomId"] === "create"
        ? false
        : route.params["staticRoomId"];

    const defaultData = {
      id: "",
      name: "",
      image: "",
      description: "",
      long_desp: "",
      status: "active",
      media: "{}",
      price: 0,
      show_type: 0,
      ordering: 0,
      require_roles: "all",
    };

    const statusLabels = [
      {
        value: 0,
        label: "Active",
      },
      {
        value: 1,
        label: "Inactive",
      },
    ];

    const formData = ref({ ...defaultData });

    const longDesp = computed(() => {
      return formData.value.long_desp;
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu nhập tên",
          trigger: "change",
        },
      ],
    });

    const isCreate = route.params["staticRoomId"] === "create";

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Cập nhật", ["Thiền phòng tĩnh"]);
      MenuComponent.reinitialization();

      // get detail
      if (staticRoomId) {
        const detail = await store
          .dispatch(StaticRoomActions.GET_DETAIL_STATIC_ROOM, staticRoomId)
          .catch((e) => {
            console.log(e);
          });

        formData.value = detail;

        const jsonMedia = JSON.parse(detail.media);
        const audio = jsonMedia["audio"] || [];
        const video = jsonMedia["video"] || [];
        store.commit(AudioMutations.SET_LIST_AUDIO, audio);
        store.commit(VideoMutations.SET_LIST_VIDEO, video);
      }
    });

    const actionCreate = (par) => {
      return store.dispatch(StaticRoomActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(StaticRoomActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;

          // update media
          formData.value.long_desp = longDesp.value;

          const audio = store.state.AudioModule.list;
          const video = store.state.VideoModule.list;
          const media = JSON.stringify({ audio, video });
          formData.value.media = media;

          const requireRoles = requireRoleRef.value.get();
          formData.value.require_roles = requireRoles as any;

          if (staticRoomId) {
            stepResult = await actionUpdate(formData.value);
          } else {
            stepResult = await actionCreate(formData.value);
          }

          loading.value = false;

          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              router.push({ name: "StaticRoom" });
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onUploadCoverSuccess = (imageUrl) => {
      formData.value.image = imageUrl;
    };

    const onChangeLongDesp = (newValue) => {
      formData.value.long_desp = newValue;
    };

    return {
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      longDesp,
      statusLabels,
      onUploadCoverSuccess,
      onChangeLongDesp,
      requireRoleRef,
    };
  },
});
