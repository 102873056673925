
import { defineComponent } from "vue";
import UpsertVideo from "@/views/static-room/child/video/UpsertVideo.vue";
import ListVideo from "@/views/static-room/child/video/ListVideo.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  VideoActions,
  VideoGetters,
  VideoMutations,
} from "@/store/vm/static-room/media/video/enums";

export default defineComponent({
  name: "video-component",
  components: {
    UpsertVideo,
    ListVideo,
  },
  computed: {},
  emits: ["change"],
  setup() {
    const store = useStore();
    const onDel = (videoId) => {
      const detail = store.getters[VideoGetters.GET_BY_ID](videoId);

      Swal.fire({
        title: "Bạn muốn xóa video?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await actionDelete(videoId);
        }
      });
    };

    const actionDelete = async (videoId) => {
      return store.commit(VideoActions.DELETE, videoId);
    };

    const onUpdate = async (videoId) => {
      return store.commit(VideoMutations.SET_VIDEO_ID_TARGET_UPSERT, videoId);
    };

    return {
      onDel,
      onUpdate,
    };
  },
});
